import React from 'react';
import Main from 'layouts/Main';
import { Box, Typography } from '@mui/material';

import {
  BigTextHeader,
  CurrentPageNavText,
  ImagePlusText,
  OurServices,
  BannerWithButton,
  PricingTable
} from 'customComponents';

import DigitalMarketing001 from 'customComponents/ImagePlusText/DigitalMarketing001.png';
import DigitalMarketing002 from 'customComponents/ImagePlusText/DigitalMarketing002.png';

const services = [
  {
    title: 'Marketing',
    description: 'A comprehensive digital marketing strategy to help your business reach and engage with your target audience.',
    avatar: '/images/avatars/Marketing.png'
  },
  {
    title: 'Easy To Sell',
    description: 'A user-friendly website and optimized sales funnel to help convert visitors into customers.',
    avatar: '/images/avatars/EasyToSell.png'
  },
  {
    title: 'More Profit',
    description: 'An effective digital marketing campaign that generates leads and sales to boost your revenue and profitability.',
    avatar: '/images/avatars/MoreProfit.png'
  },
  {
    title: 'Own Control',
    description: 'Full control over your digital marketing efforts, including website updates, content creation, and advertising campaigns.',
    avatar: '/images/avatars/OwnControl.png'
  },
];

const managedServices = [
  {
    title: 'Social Media Managment',
    description: 'Social media management is crucial for building brand awareness and engaging with customers.',
    avatar: '/images/avatars/SocialMediaManagment_002.png'
  },
  {
    title: 'Email & SMS Marketing',
    description: 'Maximize your reach with SMS & email marketing. Target specific audiences & track results for success',
    avatar: '/images/avatars/EmailNSMSMarketing.png'
  },
  {
    title: 'Handwritten Letters',
    description: 'Handwritten letters add a personal touch to your marketing efforts, building trust and connection with your audience',
    avatar: '/images/avatars/HandwrittenLetters.png'
  }
];

const pricingLevels = [
  {
    level: 'Silver',
    subtitle: 'Ideal for small businesses or startups looking to establish an online presence',
    pricingMonthly: '$299',
    pricingYearly: '$2999',
    features: [
      'Ads Setup for Instagram, Google, Facebook & Twitter',
      'Social Media Management',
      '500 Marketing SMS /Month',
      '10 Handwritten Letters /Month',
      '200 Marketing Emails /Month',
      '1 Landing Page',
    ],
  },
  {
    level: 'Gold',
    subtitle: 'Ideal for businesses that want to take their digital presence to the next level.',
    pricingMonthly: '$499',
    pricingYearly: '$4999',
    features: [
      'Ads Setup for Instagram, Google, Facebook & Twitter',
      'Social Media Management',
      '1000 Marketing SMS /Month',
      '20 Handwritten Letters /Month',
      '500 Marketing Emails /Month',
      '2 Landing Page',
    ],
  },
  {
    level: 'Custom',
    subtitle: (
      <div>
        For businesses with unique needs and requirements, we also offer a Custom Package that is tailored to your specific goals and objectives.
        <br /><br />
        This package includes a comprehensive consultation to identify your specific needs.
        <br /><br />
        Contact Us today to get started!
      </div>
    ),
  },
];

const Marketing = () => {
  const digitalMarketingTitleHTML = (
    <div>
      Making the Most of Your Digital Presence
    </div>
  );
  const digitalMarketingMainHTML = (
    <div>
      Are you ready to make the most of your online presence? Sonnul is here to help. We specialize in digital marketing and web development services that help our clients increase sales, improve customer service, and reach new audiences. We offer a full range of services including Social Media Management, Handwritten Letters and Email & SMS marketing.
    </div>
  );

  const digitalMarketingSolutionsTitleHTML = (
    <div>
      Digital Marketing Solutions
      <br />
      for Business Growth
    </div>
  );
  const digitalMarketingSolutionsMainHTML = (
    <div>
      Digital marketing can be intimidating, but it doesn't have to be! We'll guide you through the process, and make sure that your audience is hearing about your business in the best possible way. With digital marketing, you can:
      <br />
      <ul>
        <li>Get your products/services in front of more people</li>
        <li>Ensure those people are interested in what you're selling</li>
        <li>Show them how great your products are for them, day in and day out</li>
        <li>Earn their trust over time so that they become loyal customers</li>
      </ul>
    </div>
  );

  const managedServiceTitleHTML = (
    <div>
      Acquire New Customers
      <br />
      with Digital Marketing.
    </div>
  );
  const managedServiceMainHTML = (
    <div>
      Unlock the power of digital marketing to acquire new customers and grow your business. From social media ads to email campaigns, our strategies drive results and increase brand awareness.
    </div>
  );

  const ourPricingSVG = (
    <Box
    display={'flex'}
    flexDirection={'column'}
    alignItems={'center'}
    justifyContent={'center'}
    >
      <Typography
        variant="subtitle1"
        color="text.secondary"
        width='fit-content'
        sx={{
          fontWeight: 700,
        }}
      >
        Our Pricing
      </Typography>
      <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg"
        style={{
          transform: 'translateX(0px) translateY(-10px)'
        }}
      >
        <path d="M1 1C12.2037 3.26387 45.4889 6.43329 89 1" stroke="#68C8D6" stroke-linecap="round"/>
      </svg>

    </Box>
  );

  return (
    <Main colorInvert={true}>
      <BigTextHeader
        headerText={"Digital Marketing"}
        subheaderText={"Free Consultation"}
        buttonText={"Schedule Call"}
        showButton={true}
        buttonLink="/schedule-call"
      />
      <CurrentPageNavText navLocText={"Home > Digital Marketing"} />

      <ImagePlusText
        titleVariant={"h3"}
        title={digitalMarketingTitleHTML}
        mainText={digitalMarketingMainHTML}
        imageSrc={DigitalMarketing001}
        showButton={false}
        smallScreenFlexDirection={"column"}
        imageSizeLarge={420}
        imageSizeSmall={350}
        yPaddingLarge={6}
        yPaddingSmall={4}
      />

      <Box bgcolor={"background.paper2"} paddingY={6}>
        <ImagePlusText
          titleVariant={"h3"}
          title={managedServiceTitleHTML}
          mainText={managedServiceMainHTML}
          services={managedServices}
          showButton={false}
          fullScreenFlexDirection={"row"}
          smallScreenFlexDirection={"column"}
          imageSizeLarge={420}
          imageSizeSmall={350}
          yPaddingLarge={6}
          yPaddingSmall={4}
        />
      </Box>

      <OurServices
        title="Achieve Your Business Goals With Smart Digital Marketing."
        showButton={false}
        services={services}
        noBoxBackground={false}
      />
      <BannerWithButton
        text1="Schedule a quick call with our team."
        text2="We're excited to see what you have in mind!"
        buttonText="Schedule Call"
        buttonAltText="Free Consultation"
        linkUrl="/schedule-call"
      />

      <ImagePlusText
        titleVariant={"h3"}
        title={digitalMarketingSolutionsTitleHTML}
        mainText={digitalMarketingSolutionsMainHTML}
        imageSrc={DigitalMarketing002}
        showButton={true}
        smallScreenFlexDirection={"column"}
        imageSizeLarge={420}
        imageSizeSmall={350}
        yPaddingLarge={6}
        yPaddingSmall={4}
        btnLink="/contact"
        btnText="GET IN TOUCH"
      />

      <PricingTable
        upperSVG={ourPricingSVG}
        titleVariant={"h3"}
        title={"Our Pricing Packages"}
        subtitle={"No Commitments"}
        showSwitch={true}
        switchLeftText={"Pay Monthly"}
        switchRightText={"Pay Yearly"}
        pricingLevels={pricingLevels}
      />

      <BannerWithButton
        text1="Schedule a quick call with our team."
        text2="We're excited to see what you have in mind!"
        buttonText="Schedule Call"
        buttonAltText="Free Consultation"
        linkUrl="/schedule-call"
      />
    </Main>
  );
}

export default Marketing;